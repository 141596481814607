
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import { LoginValidationDto } from '../interfaces/LoginValidationDto';
  import { AuthenticationService } from '../services/authentication.service';
  import * as UrlConsts from '@/scripts/UrlConsts';



  @Component({
    components: {
    },
  })
  export default class Login extends Vue {

    loggedIn = false;

    usernameInput = "";
    passwordInput = "";

    loginError = '';

    mounted() : void {
      this.loggedIn = AuthenticationService.isLoggedIn();
    }

    /**
     * @description envoie une requète au serveur pour se connecter
     */
    login() {
      axios
        .post(  UrlConsts.login, { login: this.usernameInput, password: this.passwordInput})
        .then((response) => {
          this.setSession(response.data);
          this.$root.$emit('login');
        })
        .catch(error => {
          console.log(error);
          this.loginError = "Nom d'utilisateur ou mot de passe incorrect";
        })
    }

    /**
     * @desc met à jour les zone de texte, enlève les messages d'erreur
     */
    updateFields() : void {
      this.loginError = "";
    }

    /**
     * @description initialise une session et redirige vers l'accueil
     * @param loginResponse - la réponse positive du serveur suite à une demande de connexion
     */
    setSession(loginResponse : LoginValidationDto) {
        AuthenticationService.setSession(loginResponse);
        this.$root.$emit('login', loginResponse);

        this.$router.go(-1);
        this.$router.push("/").catch((err) =>{return});
        // envoie un événement dans tous les composant vuejs
    }
  }
